:root {
  --primary-color: #0066FF;
  --secondary-color: #00C2FF;
  --text-color: #333333;
  --background-color: #F5F8FA;
  --card-background: #FFFFFF;
  --hover-color: #004FC4;
}

body {
  font-family: 'Inter', sans-serif;
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--background-color);
  margin: 0;
  padding: 0;
}

.App {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  box-sizing: border-box;
}

header {
  text-align: center;
  margin-bottom: 60px;
}

h1 {
  color: var(--primary-color);
  font-size: 3em;
  font-weight: 700;
  margin-bottom: 20px;
}

header p {
  color: var(--text-color);
  font-size: 1.2em;
  max-width: 600px;
  margin: 0 auto;
}

.filter {
  margin-bottom: 40px;
  text-align: center;
}

.filter h2 {
  color: var(--primary-color);
  font-size: 1.8em;
  margin-bottom: 20px;
}

select {
  width: 100%;
  max-width: 300px;
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid var(--primary-color);
  background-color: var(--card-background);
  color: var(--text-color);
  cursor: pointer;
  transition: all 0.3s ease;
}

select:hover, select:focus {
  border-color: var(--secondary-color);
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 194, 255, 0.2);
}

.tool-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
}

.tool-card {
  background-color: var(--card-background);
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 30px;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.tool-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
}

.tool-card h3 {
  color: var(--primary-color);
  font-size: 1.4em;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 15px;
}

.tool-card p {
  color: var(--text-color);
  margin-bottom: 25px;
  font-size: 1em;
}

.tool-card a {
  display: inline-block;
  padding: 10px 20px;
  color: #FFFFFF;
  background-color: var(--primary-color);
  text-decoration: none;
  font-weight: 600;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  font-size: 1em;
}

.tool-card a:hover {
  background-color: var(--hover-color);
}

.tag {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: var(--secondary-color);
  color: #FFFFFF;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.8em;
  font-weight: 600;
  text-transform: uppercase;
}

.filter label {
  font-weight: 600;
  margin-right: 10px;
  color: var(--text-color);
  display: block;
  margin-bottom: 10px;
}

.no-tools {
  text-align: center;
  color: var(--text-color);
  font-size: 1.2em;
  margin-top: 40px;
  background-color: var(--card-background);
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

footer {
  text-align: center;
  margin-top: 60px;
  padding: 30px;
  color: var(--text-color);
  border-top: 1px solid #E5E5E5;
  font-size: 0.9em;
}

/* Media Queries for Responsiveness */
@media screen and (max-width: 768px) {
  .App {
    padding: 20px;
  }

  h1 {
    font-size: 2.5em;
  }

  header p {
    font-size: 1.1em;
  }

  .filter h2 {
    font-size: 1.6em;
  }

  .tool-list {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  .tool-card {
    padding: 25px;
  }
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 2em;
  }

  header p {
    font-size: 1em;
  }

  .filter h2 {
    font-size: 1.4em;
  }

  select {
    font-size: 14px;
  }

  .tool-card h3 {
    font-size: 1.3em;
  }

  .tool-card p {
    font-size: 0.9em;
  }

  .tool-card a {
    font-size: 0.9em;
    padding: 8px 16px;
  }

  .tag {
    font-size: 0.7em;
    padding: 4px 8px;
  }
}